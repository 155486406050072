import React, { Suspense } from 'react';
import { v4 as uuidv4 } from 'uuid';
import numeral from 'numeral';
import merge from 'lodash/merge';
import { Table, Box, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
//components
import TypoSemiTransparent from '../../../TypoSemiTransparent';
import TickAvatar from '../../../TickAvatar';
import Iconify from '../../../Iconify';
import ReactApexChart, { BaseOptionChart } from '../../../chart';
import { fNumber } from '../../../../utils/formatNumber';

// DATA
const DATA = [
  {
    id: uuidv4(),
    name: 'Bitcoin',
    symbol: 'BTC',
    icon: '/icons/BTC.svg',
    bg: '#F7931A',
    price: 40950.0,
    market_cap: 776298853851,
    percent_change_24h: 0.03,
    percent_change_7d: 0.112,
    chartData: [40832, 40892, 40932, 40932, 40890, 40876, 40932, 40923, 40932, 40950],
  },
  {
    id: uuidv4(),
    name: 'Solaris',
    symbol: 'XLR',
    icon: '/icons/XLR.svg',
    bg: '#FFC53B',
    price: 2926.28,
    market_cap: 351223058020,
    percent_change_24h: 1.29,
    percent_change_7d: 13.99,
    chartData: [56, 47, 40, 62, 73, 30, 23, 54, 67, 68],
  },
  {
    id: uuidv4(),
    name: 'DNotes',
    symbol: 'NOTE',
    icon: '/icons/NOTE.svg',
    bg: 'transparent',
    price: 1,
    market_cap: 80793988334,
    percent_change_24h: 0.02,
    percent_change_7d: 0.05,
    chartData: [0.56, 0.47, 0.4, 0.62, 0.73, 0.85, 0.5, 0.8, 0.9, 1],
  },
  {
    id: uuidv4(),
    name: 'Binance',
    symbol: 'BNB',
    icon: '/icons/BNB.svg',
    bg: '#F3BA2F',
    price: 397.41,
    market_cap: 65644987831,
    percent_change_24h: -0.17,
    percent_change_7d: -0.1,
    chartData: [40, 70, 75, 70, 50, 28, 7, 64, 38, 27],
  },
  {
    id: uuidv4(),
    name: 'Phore',
    symbol: 'PHR',
    icon: '/icons/PHR.svg',
    bg: '#00D188',
    price: 0.99997,
    market_cap: 52943193515,
    percent_change_24h: 5.79,
    percent_change_7d: 6.64,
    chartData: [0.87, 0.47, 0.4, 0.62, 0.82, 0.98, 0.5, 0.9, 0.7, 1],
  },
  {
    id: uuidv4(),
    name: 'Vertcoin',
    symbol: 'VTC',
    icon: '/icons/VTC.svg',
    bg: '#1B5C2E',
    price: 92.58,
    market_cap: 39219708480,
    percent_change_24h: -0.04,
    percent_change_7d: 0.09,
    chartData: [87, 47, 87.1, 62.98, 65.3, 89.01, 78.01, 91.34, 78, 92.58],
  },
];

const Trades = () => {
  const data = DATA;
  const chartOptions = merge(BaseOptionChart(), {
    chart: { animations: { enabled: true }, sparkline: { enabled: true } },
    stroke: { width: 2 },
    tooltip: {
      theme: 'dark',
      x: { show: false },
      y: {
        theme: 'dark',
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => '',
        },
      },
      marker: { show: false },
    },
  });

  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="left">Price</TableCell>
          <TableCell align="left">Market Cap</TableCell>
          <TableCell align="left">24%</TableCell>
          <TableCell align="left">7d%</TableCell>
          <TableCell align="left">Last 7 days</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((d) => (
          <TableRow key={d?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell scope="row">
              <Box component="span" display="flex" flexDirection="row" alignItems="center" gap={1}>
                <TickAvatar bg={d?.bg} height={16} width={16} component="span" sx={{ padding: '2px' }}>
                  <img src={d?.icon} alt={d?.symbol} />
                </TickAvatar>
                <Typography variant="body2" component="span">
                  {d?.name}
                </Typography>
                <TypoSemiTransparent variant="body2" component="span">
                  {d?.symbol}
                </TypoSemiTransparent>
              </Box>
            </TableCell>
            <TableCell align="left">{numeral(d?.price).format('$0,0.00')}</TableCell>
            <TableCell align="left">{numeral(d?.market_cap).format('$0,0.00')}</TableCell>
            <TableCell align="left">
              <Box display="flex" flexDirection="row" alignItems="center" gap={1} component="span">
                <Iconify
                  icon={`radix-icons:${d?.percent_change_24h >= 0 ? 'caret-up' : 'caret-down'}`}
                  color={d?.percent_change_24h >= 0 ? 'tick.bull' : 'tick.bear'}
                  sx={{ width: '16px', height: '16px' }}
                />
                <Typography
                  variant="body2"
                  component="span"
                  color={d?.percent_change_24h >= 0 ? 'tick.bull' : 'tick.bear'}
                >
                  {numeral(d?.percent_change_24h).format('0,0.00')}%
                </Typography>
              </Box>
            </TableCell>
            <TableCell align="left">
              <Box display="flex" flexDirection="row" alignItems="center" gap={1} component="span">
                <Iconify
                  icon={`radix-icons:${d?.percent_change_7d >= 0 ? 'caret-up' : 'caret-down'}`}
                  color={d?.percent_change_7d >= 0 ? 'tick.bull' : 'tick.bear'}
                  sx={{ width: '16px', height: '16px' }}
                />
                <Typography
                  variant="body2"
                  component="span"
                  color={d?.percent_change_7d >= 0 ? 'tick.bull' : 'tick.bear'}
                >
                  {numeral(d?.percent_change_7d).format('0,0.00')}%
                </Typography>
              </Box>
            </TableCell>
            <TableCell>
              <Suspense fallback={<p>Loading...</p>}>
                <ReactApexChart
                  type="line"
                  series={[{ data: d?.chartData }]}
                  options={{ ...chartOptions, colors: [d?.percent_change_7d > 0 ? '#2EFF00' : '#FD004A'] }}
                  width={100}
                  height={40}
                />
              </Suspense>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Trades;
