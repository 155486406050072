import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Stack } from '@mui/material';
// import MainFooter from './MainFooter';

// ----------------------------------------------------------------------

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default function MainLayout({ children }) {
    return (
        <Stack sx={{ minHeight: 1 }}>

            {children}

            <Box sx={{ flexGrow: 1 }} />

        </Stack>
    );
}
