//@mui
import React from 'react';
import { Link } from 'gatsby';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';

import Iconify from '../../Iconify';

const ConnectAndDonate = () => (
  <Stack spacing={3} direction={{ xs: 'column' }} mt={3}>
    <Grid item xs={12} display="flex">
      <Paper variant="hedge">
        <Box display="flex" flexDirection="row" gap={3}>
          <Box>
            <Iconify icon="tabler:wallet" sx={{ width: 40, height: 40 }} />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="body1">Connect wallet to generate referral link How it works?</Typography>
            <Link to="/exchange">
              <Typography variant="body1" component="p" sx={{ textDecoration: 'none', color: '#B36C23', fontWeight: 500, marginTop: 1, display: 'inline-block', '&:hover': { color: '#A74D01', }, }}>
                Learn more
              </Typography>
            </Link>
          </Box>
        </Box>
      </Paper>
    </Grid>
    <Grid item xs={12} display="flex">
      <Paper variant="hedge">
        <Box display="flex" flexDirection="row" gap={3}>
          <Box>
            <Iconify icon="ph:currency-circle-dollar" sx={{ width: 40, height: 40 }} />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="body1" fontWeight={500}>
              Donate Crypto to Charities
            </Typography>
            <Typography variant="body1">
              Thanks for all, you can donate bitcoin and other cryptocurrencies to a non-profit.
            </Typography>
            <Link to="/exchange">
              <Typography variant="body1" component="p" sx={{ textDecoration: 'none', color: '#B36C23', fontWeight: 500, marginTop: 1, display: 'inline-block', '&:hover': { color: '#A74D01', }, }}>
                Learn more
              </Typography>
            </Link>
          </Box>
        </Box>
      </Paper>
    </Grid>
  </Stack>
);

export default ConnectAndDonate;
