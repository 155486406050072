import React, { useEffect, useState } from "react";
import ScrollUpButton from "react-scroll-up-button";

import { styled } from '@mui/material/styles';
import { Container, Grid, Stack } from '@mui/material';

import ThemeColorPresets from "../components/ThemeColorPresets";
import ThemeProvider from '../theme/';
import Layout from "../components/layout";
import LayoutDex from "../components/layoutdex";
import Page from "../components/Page";
import { BalanceAndQuote, Chart, ConnectAndDonate, Overview, Transaction } from '../components/sections/home';

const useIsClient = () => {
	const [isClient, setClient] = useState(false);
	const key = isClient ? "client" : "server";

	useEffect(() => {
		setClient(true);
	}, []);

	return { isClient, key };
};

const RootStyle = styled('div')(() => ({
  height: '100%',
}));

const ContentStyle = styled('div')(() => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: 'transparent',
}));

const Dex = () => {
	const { isClient } = useIsClient();

  return (
    <Layout pageInfo={{ pageName: "rewards" }}>
      <ThemeProvider>
        <ThemeColorPresets>
          <ScrollUpButton ContainerClassName="custom-scroll-button" />
          <LayoutDex variant="main">
            <Page>
              <RootStyle>
                <ContentStyle>
                  <Container>
                    {isClient && <Grid container spacing={5}>
                      <Grid item md={8} xs={12}>
                        <Stack spacing={5}>
                          <Overview />
                          <Chart />
                          <Transaction />
                        </Stack>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <BalanceAndQuote />
                        <ConnectAndDonate />
                      </Grid>
                    </Grid>}
                  </Container>
                </ContentStyle>
              </RootStyle>
            </Page>
          </LayoutDex>
        </ThemeColorPresets>
      </ThemeProvider>
    </Layout>
  )
}

export default Dex;