//
import Button from './Button';
import CssBaseline from './CssBaseline';
import Link from './Link';
import Paper from './Paper';
import Switch from './Switch';
import Typography from './Typography';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(Button(theme), CssBaseline(theme), Link(theme), Paper(theme), Switch(theme), Typography(theme));
}
