// ----------------------------------------------------------------------

// xs: 0,
// sm: 720,
// md: 960,
// lg: 1260,
// xl: 1440

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1025,
    lg: 1440,
    xl: 1536,
  },
};

export default breakpoints;
