/* eslint-disable react/prop-types */
// import dynamic from 'next/dynamic';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

// const ContentEditable = dynamic(() => import('react-contenteditable'), { ssr: false });

import TickAvatar from './TickAvatar';

const GoldBox = styled(Box)(({ theme }) => ({
  display: 'block',
  height: '54px',
  width: '100%',
  padding: '1px',
  background: 'linear-gradient(103.04deg, rgba(255, 233, 134, 0.88) -25.17%, #BC7E20 77.94%, #A74D01 128.68%)',
  borderRadius: '8px',
  '& .CustomInput': {
    display: 'flex',
    alignSelf: 'center',
    padding: '12px',
    width: 'auto',
    minWidth: '32px',
    maxWidth: '222px',
    overflow: 'hidden',
    height: '48px',
    background: 'transparent',
    border: 'none',
    fontSize: '16px',
    fontWeight: 600,
    color: '#FFFF',
    position: 'relative',
    '&:focus': {
      outline: 'none',
    },
  },
}));

const Symbol = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 600,
  background: 'linear-gradient(103.04deg, rgba(255, 233, 134, 0.88) -25.17%, #BC7E20 77.94%, #A74D01 128.68%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  display: 'flex',
  alignSelf: 'center',
  '&.white': {
    color: '#ffff',
    background: 'none',
    WebkitBackgroundClip: 'unset',
    WebkitTextFillColor: 'unset',
  },
}));

const CurrencyInput = ({ noArrow, quote = null, append = null, from = false, to = false }) => {
  const data = quote;
  // const [, setValue] = useState(to ? numeral(quote?.price).format('0,0.00') : '1');

  return (
    <GoldBox>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="12px"
        sx={{ background: '#142625', borderRadius: '8px', height: '52px', padding: '15px 24px' }}
      >
        {data?.icon && (
          <TickAvatar height={18} width={18} bg={data?.bg}>
            <img src={data?.icon} alt={data?.symbol} sx={{ bg: '#F7931A', color: '#fff' }} />
          </TickAvatar>
        )}
        <Box display="flex" flexGrow="1">
          {/* <ContentEditable
            className="CustomInput"
            innerRef={editableSpan}
            html={value}
            disabled={false}
            onChange={changeHandler}
            tagName="span"
          /> */}
          {!append ? (
            <Symbol>{data?.symbol}</Symbol>
          ) : (
            <Symbol className="white" component="span">
              {append}
            </Symbol>
          )}
        </Box>
        {!noArrow && (
          <Box sx={{ cursor: 'pointer' }}>
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L7 7L13 1" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
              <path
                d="M1 1L7 7L13 1"
                stroke="url(#paint0_linear_521_1583)"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_521_1583"
                  x1="0.557046"
                  y1="-6.07143"
                  x2="19.2764"
                  y2="2.60286"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FFE986" stopOpacity="0.88" />
                  <stop offset="0.670219" stopColor="#BC7E20" />
                  <stop offset="0.670319" stopColor="#BC7D1F" />
                  <stop offset="1" stopColor="#A74D01" />
                </linearGradient>
              </defs>
            </svg>
          </Box>
        )}
      </Box>
    </GoldBox>
  );
};
export default CurrencyInput;
