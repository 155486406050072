import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { Table, Box, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
//components
import Iconify from '../../../Iconify';
import truncateMiddle from '../../../../utils/truncateMiddle';

// DATA
const DATA = [
  {
    id: uuidv4(),
    type: 'SELL',
    created_at: '2022-04-01',
    asset_name: 'ETH',
    amount: 12,
    address: '0xc75aa1fa199eac5adabc832ea4522cff6dfd521a',
    status: 'completed',
  },
  {
    id: uuidv4(),
    type: 'EXCHANGE',
    created_at: '2022-04-01',
    asset_name: 'ETH',
    amount: 12,
    address: '0xc75aa1fa199eac5adabc832ea4522cff6dfd521a',
    status: 'completed',
  },
  {
    id: uuidv4(),
    type: 'EXCHANGE',
    created_at: '2022-04-01',
    asset_name: 'ETH',
    amount: 12,
    address: '0xc75aa1fa199eac5adabc832ea4522cff6dfd521a',
    status: 'failed',
  },
  {
    id: uuidv4(),
    type: 'BUY',
    created_at: '2022-04-01',
    asset_name: 'ETH',
    amount: 12,
    address: '0xc75aa1fa199eac5adabc832ea4522cff6dfd521a',
    status: 'completed',
  },
  {
    id: uuidv4(),
    type: 'BUY',
    created_at: '2022-04-01',
    asset_name: 'ETH',
    amount: 12,
    address: '0xc75aa1fa199eac5adabc832ea4522cff6dfd521a',
    status: 'failed',
  },
  {
    id: uuidv4(),
    type: 'SELL',
    created_at: '2022-04-01',
    asset_name: 'ETH',
    amount: 12,
    address: '0xc75aa1fa199eac5adabc832ea4522cff6dfd521a',
    status: 'completed',
  },
];

const IconWrapper = styled(Box)(() => ({
  borderRadius: '50%',
  height: '24px',
  width: '24px',
  padding: '4px',
  '&.EXCHANGE': {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    color: 'rgba(18, 51, 49, 1)',
  },
  '&.SELL': {
    backgroundColor: 'rgba(253, 0, 74, 0.7)',
    color: '#ffff',
  },
  '&.BUY': {
    backgroundColor: 'rgba(46, 255, 0, 0.7)',
    color: '#ffff',
  },
}));

const OrderHistory = () => {
  const data = DATA;

  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="left">Type</TableCell>
          <TableCell align="left">Date</TableCell>
          <TableCell align="left">Tokens</TableCell>
          <TableCell align="left">Amount</TableCell>
          <TableCell align="left">Address</TableCell>
          <TableCell align="left">Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((d) => (
          <TableRow key={d?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell scope="row">
              <Box component="span" display="flex" flexDirection="row" alignItems="center" gap={1}>
                <IconWrapper className={d?.type}>
                  <Iconify
                    icon={clsx({
                      'gg:arrows-exchange': d?.type === 'EXCHANGE',
                      'bi:arrow-up-short': d?.type === 'SELL',
                      'bi:arrow-down-short': d?.type === 'BUY',
                    })}
                    height={16}
                    width={16}
                  />
                </IconWrapper>
                <Typography variant="body2" component="span" sx={{ textTransform: 'capitalize' }}>
                  {d?.type}
                </Typography>
              </Box>
            </TableCell>
            <TableCell align="left">{d?.created_at}</TableCell>
            <TableCell align="left">{d?.asset_name}</TableCell>
            <TableCell align="left">
              {d?.amount} {d?.asset_name}
            </TableCell>
            <TableCell align="left">{truncateMiddle(d?.address, 16, '...', 7)}</TableCell>
            <TableCell>
              <Typography
                sx={{ color: clsx({ 'tick.bull': d?.status === 'completed', 'tick.bear': d?.status === 'failed' }), textTransform: 'capitalize' }}
              >
                {d?.status}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default OrderHistory;
