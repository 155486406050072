/* eslint-disable no-extra-boolean-cast */
import React, { useState } from 'react';

// @mui
import { Box, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import OrderHistory from './OrderHistory';
import Trades from './Trades';

// CONSTANTS
const TABLE_TYPES = {
  TRADES: 'trades',
  OPEN_ORDERS: 'open_orders',
  ORDER_HISTORY: 'order_history',
};

const TitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const TableType = styled((props) => <Typography {...props} />)(({ active }) => ({
  color: active === 'true' ? '#ffff' : '#a9a9a9',
  cursor: 'pointer',
}));

const Transaction = () => {
  const [tableType, setTableType] = useState(TABLE_TYPES.TRADES);

  return (
    <Paper variant="hedge" className='transaction-dex'>
      <Stack spacing={2}>
        <TitleWrapper>
          <Box display="flex" flexGrow={1}>
            <Typography variant="body2">HedgePay Order Book Log</Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap={2}>
            <TableType
              variant="body2"
              component="a"
              active={`${tableType === TABLE_TYPES.TRADES}`}
              onClick={() => setTableType(TABLE_TYPES.TRADES)}
            >
              Trades
            </TableType>
            <TableType
              variant="body2"
              component="a"
              active={`${tableType === TABLE_TYPES.OPEN_ORDERS}`}
              onClick={() => setTableType(TABLE_TYPES.OPEN_ORDERS)}
            >
              Open orders
            </TableType>
            <TableType
              variant="body2"
              component="a"
              active={`${tableType === TABLE_TYPES.ORDER_HISTORY}`}
              onClick={() => setTableType(TABLE_TYPES.ORDER_HISTORY)}
            >
              Order History
            </TableType>
          </Box>
        </TitleWrapper>
        <Box sx={{ overflowX: 'auto', overflowY: 'hidden' }}>
          {(tableType === TABLE_TYPES.TRADES || tableType === TABLE_TYPES.OPEN_ORDERS) && <Trades />}
          {tableType === TABLE_TYPES.ORDER_HISTORY && <OrderHistory />}
        </Box>
      </Stack>
    </Paper>
  );
};

export default Transaction;

export { default as OrderHistory } from './OrderHistory';
export { default as Trades } from './Trades';

