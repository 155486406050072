// LAYOUT
// ----------------------------------------------------------------------


export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const API_CONFIG = {
  COINMARKETCAP_API_URL: "COINMARKETCAP_API_URL",
  COINMARKETCAP_API_KEY: "COINMARKETCAP_API_KEY",
};

export const SERVER_API_PATH = {
  QUOTE: `${API_CONFIG.COINMARKETCAP_API_URL}/cryptocurrency/quotes/latest?symbol=HPAY`,
};

export const BROWSER_API_PATH = {
  QUOTE: `/api/quote`,
};

// SETTINGS
// ----------------------------------------------------------------------

export const cookiesExpires = 3;

export const cookiesKey = {
  themeMode: 'themeMode',
  themeColorPresets: 'themeColorPresets',
};

export const defaultSettings = {
  themeMode: 'dark',
  themeColorPresets: 'default',
};
