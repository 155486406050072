import hexToRgba from 'hex-to-rgba';
import numeral from 'numeral';
import React, { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
//@mui
import { Box, Button, IconButton, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

//components
import BTC from '../../../../static/icons/BTC.svg';
import NetworkContext from '../../../context/network-context';
import CurrencyInput from '../../CurrencyInput';
import Iconify from '../../Iconify';
import SwapLimit from '../../SwapLimit';
import TickAvatar from '../../TickAvatar';
import TypoSemiTransparent from '../../TypoSemiTransparent';
import DropData from './static';


// Styled

const RootStyle = styled((props) => <Paper {...props} variant="hedge" />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  '& .limitPriceExpired': {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  }
}));

const QuoteContainer = styled(Box)(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: 'rgba(97, 97, 97, 0.4)',
  marginTop: theme.spacing(3),
  paddingTop: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
}));

const HedgeIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 8,
  background: hexToRgba('#ffff', 0.08),
  width: 36,
  height: 36,
  '&:hover': {
    '&>svg': {
      color: '#FFE986',
    },
  },
}));

const ConnectButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  color: '#fff',
  height: '54px',
  marginTop: theme.spacing(4),
  width: '100%',
  background: 'linear-gradient(103.04deg, rgba(255, 233, 134, 0.88) -25.17%, #BC7E20 77.94%, #A74D01 128.68%)',
  '&:hover': {
    background: 'linear-gradient(3.04deg, rgba(255, 233, 134, 0.88) -25.17%, #BC7E20 77.94%, #A74D01 128.68%)',
  },
}));

const IconBtn = styled('button')(({ theme }) => ({
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  width: '26px',
  height: '26px',
  padding: '2px',
  display: 'flex',
  fontSize: '16px',
  alignSelf: 'center',
  '&:focus': {
    outline: 'none',
  },
}));

// CONSTANTS
const TRANSACTION_TYPE = {
  SWAP: 'SWAP',
  LIMIT: 'LIMIT',
};

const DATA = {
  balance: 18988.04,
  percent_24h: 58.11,
  quote: {
    name: 'Bitcoin',
    symbol: 'BTC',
    icon: '/icons/BTC.svg',
    bg: '#F7931A',
    value: 42550,
  },
};

const FROM = {
  name: 'Bitcoin',
  symbol: 'BTC',
  icon: '/icons/BTC.svg',
  bg: '#F7931A',
};
const TO = {
  name: 'US Dollar',
  symbol: 'USD',
  icon: '/icons/USD.svg',
  bg: '#83BD67',
};

const BalanceAndQuote = () => {
  const data = DATA;
  const { setShowWalletsModal} = useContext(NetworkContext);
  const [transactionType, setTransactionType] = useState(TRANSACTION_TYPE.SWAP);
  const [quote,] = useState({ from: FROM, to: TO, price: 46734.26 });
  const [startDate, setStartDate] = useState(new Date());
  const [dropValue, setDropValue] = useState('BTC');
  const [priceValue, setPriceValue] = useState(DropData[0].Price);

  const handleClick = (data) => {
    setDropValue(data?.symbol)
    setPriceValue(data?.Price)
  }

  const handleSubmit = async() =>{
    setShowWalletsModal(true);
    // try {
    //   let body = {
    //     price: 10,
    //     amount: 10,
    //     orderCreator: dropValue,
    //     createdAt: new Date(),
    //     transaction: transactionType
    //   }
    //   const response = await axios.post(`/orders`, body, { headers: { 'accept': 'application/json', 'Content-Type': 'application/json' } });
    //   toast.success("orders connect wallet successfully");
    // } catch (error) {
    //   toast.error("Something goes wrong here, Try again");
    // }
  }

  return (
    <RootStyle>
      <TypoSemiTransparent variant="body1" fontWeight="500">
        Your balance
      </TypoSemiTransparent>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h4" component="h4" color="common.white">
          {numeral(data?.balance).format('$0,0.00')}
        </Typography>

        <Typography variant="body2" component="span" color={data?.percent_24h >= 0 ? 'tick.bull' : 'tick.bear'} sx={{ ml: 1 }}>
          ({numeral(data?.percent_24h).format('0,0.00')}%)
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
        <TickAvatar height={18} width={18} bg={data?.quote?.bg}>
          <img src={data?.quote?.icon} alt={data?.quote?.symbol} />
        </TickAvatar>
        <TypoSemiTransparent variant="body2" component="span" color="common.white">
          {numeral(data?.quote?.value).format('0,0')} {data?.quote?.symbol}
        </TypoSemiTransparent>
      </Box>
      <QuoteContainer>
        <Box display="flex" flexDirection="row">
          <Box display="flex" flexDirection="row" flexGrow={1}>
            <SwapLimit value={transactionType} onChange={() => setTransactionType(transactionType === TRANSACTION_TYPE.SWAP ? TRANSACTION_TYPE.LIMIT : TRANSACTION_TYPE.SWAP)} />
          </Box>
          <Box display="flex" flex-direction="row" alignItems="center" gap={'12px'}>
            <HedgeIconButton>
              <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.33342 5.16678H4.91758L4.91842 5.15928C5.02723 4.62647 5.24019 4.1204 5.54508 3.67011C5.99879 3.00163 6.6352 2.47774 7.37842 2.16094C7.63008 2.05428 7.89258 1.97261 8.16008 1.91844C8.71499 1.80594 9.28684 1.80594 9.84175 1.91844C10.6392 2.08185 11.371 2.47629 11.9459 3.05261L13.1259 1.87594C12.5941 1.34398 11.9649 0.919312 11.2726 0.62511C10.9195 0.475635 10.5524 0.361738 10.1767 0.28511C9.40161 0.127619 8.60272 0.127619 7.82758 0.28511C7.45162 0.362056 7.08426 0.476229 6.73092 0.625944C5.68969 1.0677 4.79836 1.80102 4.16425 2.73761C3.73749 3.36915 3.43905 4.07844 3.28591 4.82511C3.26258 4.93761 3.25008 5.05261 3.23341 5.16678H0.666748L4.00008 8.50011L7.33342 5.16678ZM10.6667 6.83344H13.0826L13.0817 6.84011C12.864 7.90825 12.2344 8.84768 11.3292 9.45511C10.8791 9.76025 10.373 9.97324 9.84008 10.0818C9.28545 10.1943 8.71388 10.1943 8.15925 10.0818C7.62644 9.97297 7.12037 9.76 6.67008 9.45511C6.44893 9.30552 6.24245 9.13532 6.05341 8.94678L4.87508 10.1251C5.40717 10.6569 6.03669 11.0813 6.72925 11.3751C7.08258 11.5251 7.45175 11.6393 7.82508 11.7151C8.59993 11.8727 9.39857 11.8727 10.1734 11.7151C11.6672 11.4051 12.9811 10.5246 13.8359 9.26094C14.2623 8.62988 14.5604 7.92117 14.7134 7.17511C14.7359 7.06261 14.7492 6.94761 14.7659 6.83344H17.3334L14.0001 3.50011L10.6667 6.83344Z" fill="black" />
                <path d="M7.33342 5.16678H4.91758L4.91842 5.15928C5.02723 4.62647 5.24019 4.1204 5.54508 3.67011C5.99879 3.00163 6.6352 2.47774 7.37842 2.16094C7.63008 2.05428 7.89258 1.97261 8.16008 1.91844C8.71499 1.80594 9.28684 1.80594 9.84175 1.91844C10.6392 2.08185 11.371 2.47629 11.9459 3.05261L13.1259 1.87594C12.5941 1.34398 11.9649 0.919312 11.2726 0.62511C10.9195 0.475635 10.5524 0.361738 10.1767 0.28511C9.40161 0.127619 8.60272 0.127619 7.82758 0.28511C7.45162 0.362056 7.08426 0.476229 6.73092 0.625944C5.68969 1.0677 4.79836 1.80102 4.16425 2.73761C3.73749 3.36915 3.43905 4.07844 3.28591 4.82511C3.26258 4.93761 3.25008 5.05261 3.23341 5.16678H0.666748L4.00008 8.50011L7.33342 5.16678ZM10.6667 6.83344H13.0826L13.0817 6.84011C12.864 7.90825 12.2344 8.84768 11.3292 9.45511C10.8791 9.76025 10.373 9.97324 9.84008 10.0818C9.28545 10.1943 8.71388 10.1943 8.15925 10.0818C7.62644 9.97297 7.12037 9.76 6.67008 9.45511C6.44893 9.30552 6.24245 9.13532 6.05341 8.94678L4.87508 10.1251C5.40717 10.6569 6.03669 11.0813 6.72925 11.3751C7.08258 11.5251 7.45175 11.6393 7.82508 11.7151C8.59993 11.8727 9.39857 11.8727 10.1734 11.7151C11.6672 11.4051 12.9811 10.5246 13.8359 9.26094C14.2623 8.62988 14.5604 7.92117 14.7134 7.17511C14.7359 7.06261 14.7492 6.94761 14.7659 6.83344H17.3334L14.0001 3.50011L10.6667 6.83344Z" fill="url(#paint0_linear_524_1778)" />
                <defs>
                  <linearGradient id="paint0_linear_524_1778" x1="0.0515345" y1="-13.5826" x2="28.5149" y2="-4.16121" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFE986" stopOpacity="0.88" />
                    <stop offset="0.670219" stopColor="#BC7E20" />
                    <stop offset="1" stopColor="#A74D01" />
                  </linearGradient>
                </defs>
              </svg>
            </HedgeIconButton>
            <HedgeIconButton>
              <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.666748 1.16634C0.666748 0.945327 0.754545 0.733366 0.910826 0.577086C1.06711 0.420805 1.27907 0.333008 1.50008 0.333008H16.5001C16.7211 0.333008 16.9331 0.420805 17.0893 0.577086C17.2456 0.733366 17.3334 0.945327 17.3334 1.16634C17.3334 1.38735 17.2456 1.59932 17.0893 1.7556C16.9331 1.91188 16.7211 1.99967 16.5001 1.99967H1.50008C1.27907 1.99967 1.06711 1.91188 0.910826 1.7556C0.754545 1.59932 0.666748 1.38735 0.666748 1.16634ZM4.00008 6.16634C4.00008 5.94533 4.08788 5.73337 4.24416 5.57709C4.40044 5.42081 4.6124 5.33301 4.83342 5.33301H13.1667C13.3878 5.33301 13.5997 5.42081 13.756 5.57709C13.9123 5.73337 14.0001 5.94533 14.0001 6.16634C14.0001 6.38735 13.9123 6.59932 13.756 6.7556C13.5997 6.91188 13.3878 6.99967 13.1667 6.99967H4.83342C4.6124 6.99967 4.40044 6.91188 4.24416 6.7556C4.08788 6.59932 4.00008 6.38735 4.00008 6.16634ZM7.33342 10.333C7.1124 10.333 6.90044 10.4208 6.74416 10.5771C6.58788 10.7334 6.50008 10.9453 6.50008 11.1663C6.50008 11.3874 6.58788 11.5993 6.74416 11.7556C6.90044 11.9119 7.1124 11.9997 7.33342 11.9997H10.6667C10.8878 11.9997 11.0997 11.9119 11.256 11.7556C11.4123 11.5993 11.5001 11.3874 11.5001 11.1663C11.5001 10.9453 11.4123 10.7334 11.256 10.5771C11.0997 10.4208 10.8878 10.333 10.6667 10.333H7.33342Z" fill="black" />
                <path d="M0.666748 1.16634C0.666748 0.945327 0.754545 0.733366 0.910826 0.577086C1.06711 0.420805 1.27907 0.333008 1.50008 0.333008H16.5001C16.7211 0.333008 16.9331 0.420805 17.0893 0.577086C17.2456 0.733366 17.3334 0.945327 17.3334 1.16634C17.3334 1.38735 17.2456 1.59932 17.0893 1.7556C16.9331 1.91188 16.7211 1.99967 16.5001 1.99967H1.50008C1.27907 1.99967 1.06711 1.91188 0.910826 1.7556C0.754545 1.59932 0.666748 1.38735 0.666748 1.16634ZM4.00008 6.16634C4.00008 5.94533 4.08788 5.73337 4.24416 5.57709C4.40044 5.42081 4.6124 5.33301 4.83342 5.33301H13.1667C13.3878 5.33301 13.5997 5.42081 13.756 5.57709C13.9123 5.73337 14.0001 5.94533 14.0001 6.16634C14.0001 6.38735 13.9123 6.59932 13.756 6.7556C13.5997 6.91188 13.3878 6.99967 13.1667 6.99967H4.83342C4.6124 6.99967 4.40044 6.91188 4.24416 6.7556C4.08788 6.59932 4.00008 6.38735 4.00008 6.16634ZM7.33342 10.333C7.1124 10.333 6.90044 10.4208 6.74416 10.5771C6.58788 10.7334 6.50008 10.9453 6.50008 11.1663C6.50008 11.3874 6.58788 11.5993 6.74416 11.7556C6.90044 11.9119 7.1124 11.9997 7.33342 11.9997H10.6667C10.8878 11.9997 11.0997 11.9119 11.256 11.7556C11.4123 11.5993 11.5001 11.3874 11.5001 11.1663C11.5001 10.9453 11.4123 10.7334 11.256 10.5771C11.0997 10.4208 10.8878 10.333 10.6667 10.333H7.33342Z" fill="url(#paint0_linear_533_994)" />
                <defs>
                  <linearGradient id="paint0_linear_533_994" x1="0.0515345" y1="-13.417" x2="28.515" y2="-3.99586" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFE986" stopOpacity="0.88" />
                    <stop offset="0.670219" stopColor="#BC7E20" />
                    <stop offset="1" stopColor="#A74D01" />
                  </linearGradient>
                </defs>
              </svg>
            </HedgeIconButton>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={'12px'} sx={{ mt: 4 }}>
          <Typography variant="body2">You Pay</Typography>
          {/* <CurrencyInput quote={{ ...quote?.from, price: quote?.price }} from /> */}
          <Dropdown className='dropdownCust'>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <TickAvatar height={18} width={18} >
                <img src={BTC} alt="symbol" sx={{ bg: '#F7931A', color: '#fff' }} />
              </TickAvatar>
              <span>{dropValue}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {DropData.map((data) => (
                <Dropdown.Item onClick={() => handleClick(data)}>
                  <TickAvatar height={18} width={18} >
                    <img src={BTC} alt="symbol" sx={{ bg: '#F7931A', color: '#fff' }} />
                  </TickAvatar>
                  {data?.tokenName}
                  </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          {transactionType === 'LIMIT' && (
            <Box display="flex" flexDirection="row" gap={3} className="limitPriceExpired">
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <Typography variant="body2">Price</Typography>
                <CurrencyInput quote={{ symbol: `${priceValue}`, price: '2013' }} to noArrow />
              </Box>
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <Typography variant="body2">Expired in</Typography>
                {/* <CurrencyInput append="Days" /> */}
                <Box className='datePickerSect'>
                  <ReactDatePicker timeInputLabel="Time: " className='dateandTimePicker1' minDate={new Date()} minTime={new Date()} dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput onChange={(date) => setStartDate(date)} selected={startDate}/>
                </Box>
              </Box>
            </Box>
          )}
          <IconBtn type="button">
            <Iconify icon="ion:swap-horizontal-outline" width={24} height={24} rotate={1} color="#fff" />
          </IconBtn>
          <Typography variant="body2">You will receive</Typography>
          <CurrencyInput quote={{ ...quote?.to, price: quote?.price }} to />
        </Box>
        <Box>
          <ConnectButton onClick={() => handleSubmit()}>CONNECT WALLET</ConnectButton>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" sx={{ color: '#9E9E9E' }}>
            *Price includes gas fee
          </Typography>
        </Box>
      </QuoteContainer>
    </RootStyle>
  );
};

export default BalanceAndQuote;
