import React, { useCallback, useEffect, useState } from 'react';
import numeral from 'numeral';
import CopyToClipboard from 'react-copy-to-clipboard';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, Paper, Typography, Tooltip, Skeleton } from '@mui/material';
// config
import { BROWSER_API_PATH } from '../../../config';
//utils
import truncateMiddle from '../../../utils/truncateMiddle';
// components
import TypoSemiTransparent from '../../TypoSemiTransparent';
import TickAvatar from '../../TickAvatar';
import Iconify from '../../Iconify';

const HedgeTick = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'center',
}));

const ContractWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const StatsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  [theme.breakpoints.between('sm', 'md')]: {
    display: 'inline-block',
    width: '100%',
  },
}));

const Stat = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  [theme.breakpoints.between('sm', 'md')]: {
    width: '50%',
    display: 'inline-block',
    marginBottom: theme.spacing(2),
  },
}));

const Overview = () => {
  const theme = useTheme();
  // const { enqueueSnackbar } = useSnackbar();
  const [overview, setOverview] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      // To check the response - try http://localhost:3020/api/quote
      const resData = await fetch(BROWSER_API_PATH.QUOTE).then((res) => res.json());
      setIsLoading(false);
      setOverview(resData);
    } catch (err) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!overview) fetchData();
  }, [overview, fetchData]);

  const quote = overview?.quote?.USD;
  const percentChange1h = overview?.quote?.USD?.percent_change_1h;

  return (
    <Paper variant="hedge" className='overview-main'>
      <Grid container spacing={2} sx={{ pb: 4 }}>
        <Grid item sm={8} sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
          {isLoading ? (
            <HedgeTick>
              <Skeleton animation="wave" width={32} height={32} />
              <Skeleton animation="wave" width={100} height={32} />
            </HedgeTick>
          ) : (
            <HedgeTick>
              <TickAvatar>
                <img src="/img/logo.png" alt="HPAY" sx={{ bg: '#09706C', color: '#fff' }} />
              </TickAvatar>
              <Typography variant="h5" fontWeight={500} component="h5" color="text.bright">
                {overview?.name}{' '}
                <TypoSemiTransparent variant="h5" component="span" fontWeight={500}>
                  {overview?.symbol || '--'}
                </TypoSemiTransparent>
              </Typography>
            </HedgeTick>
          )}
          <ContractWrapper>
            <TypoSemiTransparent variant="body2" fontWeight="500" component="span" sx={{ pr: '10px' }}>
              Contract:
            </TypoSemiTransparent>
            {isLoading ? (
              <Box>
                <Skeleton animation="wave" width={240} height={24} />
              </Box>
            ) : (
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="body2" fontWeight={400} component="span" color="text.bright">
                  {truncateMiddle(`${overview?.platform?.name}${overview?.platform?.token_address}`, 39, '...', 7)}
                </Typography>
                <CopyToClipboard
                  text={overview?.platform?.token_address}
                  onCopy={() => {
                    // enqueueSnackbar('Address has been copied!');
                  }}
                >
                  <Tooltip title="Copy" placement="top" arrow>
                    <TypoSemiTransparent
                      variant="body2"
                      fontWeight="500"
                      component="a"
                      sx={{ pl: '10px', cursor: 'pointer', display: 'flex', alignSelf: 'center' }}
                    >
                      <Iconify icon="tabler:copy" sx={{ height: '16px', width: '16px' }} />
                    </TypoSemiTransparent>
                  </Tooltip>
                </CopyToClipboard>
              </Box>
            )}
          </ContractWrapper>
        </Grid>
        <Grid item sm={4}>
          <TypoSemiTransparent variant="body2" fontWeight="500" component="div" sx={{ pr: '10px' }}>
            HedgePay Price
          </TypoSemiTransparent>
          {isLoading ? (
            <Box display="flex" flexDirection="row" alignItems="center">
              <Skeleton animation="wave" width={120} height={36} />
              <Skeleton animation="wave" width={40} height={32} sx={{ ml: 1 }} />
            </Box>
          ) : (
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="h4" component="h4" color="common.white">
                {numeral(quote?.price).format('$0,0.000000')}
              </Typography>
              <Iconify
                icon={`radix-icons:${percentChange1h >= 0 ? 'caret-up' : 'caret-down'}`}
                color={percentChange1h >= 0 ? 'tick.bull' : 'tick.bear'}
                sx={{ width: '16px', height: '16px', ml: 1 }}
              />
              <Typography
                variant="body2"
                component="span"
                color={percentChange1h >= 0 ? 'tick.bull' : 'tick.bear'}
                sx={{ ml: '3px' }}
              >
                {numeral(percentChange1h).format('0,0.00')}%
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <Box sx={{ borderTop: '1px solid rgba(97, 97, 97, 0.2)', pt: 4 }}>
        <StatsWrapper>
          <Stat>
            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
              <TypoSemiTransparent variant="body2" fontWeight="500" component="div">
                Market Cap
              </TypoSemiTransparent>
              <Tooltip
                title="The total market value of a cryptocurrency's circulating supply. It is analogous to the free-float capitalization in the stock market."
                placement="top"
                arrow
              >
                <Box display="flex" alignSelf="center">
                  <Iconify icon="ant-design:exclamation-circle-outlined" />
                </Box>
              </Tooltip>
            </Box>
            {isLoading ? (
              <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                <Skeleton animation="wave" height={24} width={80} />
                <Skeleton animation="wave" height={24} width={40} />
              </Box>
            ) : (
              <Box display="flex" flexDirection="row" alignItems="center" gap={0}>
                <Typography variant="body2" component="span" color="common.white">
                  {numeral(overview?.self_reported_market_cap).format('$0,0')}
                </Typography>
                <Iconify
                  icon={`radix-icons:${quote?.market_cap >= 0 ? 'caret-up' : 'caret-down'}`}
                  color={quote?.market_cap >= 0 ? 'tick.bull' : 'tick.bear'}
                  sx={{ width: '16px', height: '16px', ml: 1 }}
                />
                <Typography variant="body2" component="span" color={quote?.market_cap >= 0 ? 'tick.bull' : 'tick.bear'}>
                  {numeral(quote?.market_cap).format('0,0.00')}%
                </Typography>
              </Box>
            )}
          </Stat>
          <Stat>
            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
              <TypoSemiTransparent variant="body2" fontWeight="500" component="div">
                Fully Diluted Market Cap
              </TypoSemiTransparent>
              <Tooltip title="The market cap if the max supply was in circulation." placement="top" arrow>
                <Box display="flex" alignSelf="center">
                  <Iconify icon="ant-design:exclamation-circle-outlined" />
                </Box>
              </Tooltip>
            </Box>
            {isLoading ? (
              <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                <Skeleton animation="wave" height={24} width={80} />
                <Skeleton animation="wave" height={24} width={40} />
              </Box>
            ) : (
              <Box display="flex" flexDirection="row" alignItems="center" gap={0}>
                <Typography variant="body2" component="span" color="common.white">
                  {numeral(quote?.fully_diluted_market_cap).format('$0,0')}
                </Typography>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Iconify
                    icon={`radix-icons:${percentChange1h >= 0 ? 'caret-up' : 'caret-down'}`}
                    color={percentChange1h >= 0 ? 'tick.bull' : 'tick.bear'}
                    sx={{ width: '16px', height: '16px', ml: 1 }}
                  />
                  <Typography variant="body2" component="span" color={percentChange1h >= 0 ? 'tick.bull' : 'tick.bear'}>
                    {numeral(percentChange1h).format('0,0.00')}%
                  </Typography>
                </Box>
              </Box>
            )}
          </Stat>
          <Stat>
            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
              <TypoSemiTransparent variant="body2" fontWeight="500" component="div">
                Volume
              </TypoSemiTransparent>
              <Tooltip
                title="A measure of how much of a cryptocurrency was swaped in the last 24 hours."
                placement="top"
                arrow
              >
                <Box display="flex" alignSelf="center">
                  <Iconify icon="ant-design:exclamation-circle-outlined" />
                </Box>
              </Tooltip>
            </Box>
            {isLoading ? (
              <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                <Skeleton animation="wave" height={24} width={80} />
                <Skeleton animation="wave" height={24} width={40} />
              </Box>
            ) : (
              <Box display="flex" flexDirection="row" alignItems="center" gap={0}>
                <Typography variant="body2" component="span" color="common.white">
                  {numeral(quote?.volume_24h).format('$0,0')}
                </Typography>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Iconify
                    icon={`radix-icons:${quote?.volume_change_24h >= 0 ? 'caret-up' : 'caret-down'}`}
                    color={quote?.volume_change_24h >= 0 ? 'tick.bull' : 'tick.bear'}
                    sx={{ width: '16px', height: '16px', ml: 1 }}
                  />
                  <Typography
                    variant="body2"
                    component="span"
                    color={quote?.volume_change_24h >= 0 ? 'tick.bull' : 'tick.bear'}
                  >
                    {numeral(quote?.volume_change_24h).format('0,0.00')}%
                  </Typography>
                </Box>
              </Box>
            )}
          </Stat>
          <Stat>
            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
              <TypoSemiTransparent variant="body2" fontWeight="500" component="div">
                Chain
              </TypoSemiTransparent>
            </Box>
            {isLoading ? (
              <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                <Skeleton animation="wave" height={24} width={80} />
                <Skeleton animation="wave" height={24} width={40} />
              </Box>
            ) : (
              <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                <TickAvatar height={18} width={18} bg="#F3BA2F">
                  <img src="/icons/BSC.svg" alt="BSC" sx={{ bg: '#09706C', color: '#fff' }} />
                </TickAvatar>
                <Typography variant="body2" component="span" color="common.white">
                  {overview?.platform?.name}
                </Typography>
              </Box>
            )}
          </Stat>
        </StatsWrapper>
      </Box>
    </Paper>
  );
};

export default Overview;
