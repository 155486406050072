const DropData = [
    {
        "tokenName": "Bitcoin",
        "contractAddress": "bc832ea4522cff6dfd521abc832ea4522cff6dfd521abc832ea4522cff6dfd521a",
        "symbol": "BTC",
        "Price": 200,
        "hedgePay-price": 123,
        "market-cap": 25335,
        "fully-diluted": 26556,
        "volume": 25,
        "chain": 'BNB',
        "your-balance": 255454888,
        "price-btc": 145
    },
    {
        "tokenName": "Ethereumcoin",
        "contractAddress": "0xc75aa1fa199eac5adabc832ea4522cff6dfd521abc832ea4522cff6dfd521a",
        "symbol": "ETH",
        "Price": 400,
        "hedgePay-price": 0.333,
        "market-cap": 525255,
        "fully-diluted": 52154,
        "volume": 5,
        "chain": 'ERC',
        "your-balance": 5688477,
        "price-btc": 54
    },
    {
        "tokenName": "Litecoin",
        "contractAddress": "0xc75aa1fa1f6dfd521abc832ea4522cff6dfd521abc832ea4522cff6dfd521a",
        "symbol": "Lite",
        "Price": 100,
        "hedgePay-price": 22333,
        "market-cap": 51544,
        "fully-diluted": 545444,
        "volume": 2,
        "chain": 'ETH',
        "your-balance": 885454,
        "price-btc": 88
    },
    {
        "tokenName": "Terracoin",
        "contractAddress": "0xc75aa1fa1f6dfd521abc832ea4522cff6dfd521abc832ea4522cff6dfd521a",
        "symbol": "TERRA",
        "Price": 130,
        "hedgePay-price": 256.25,
        "market-cap": 577445,
        "fully-diluted": 554666,
        "volume": 588,
        "chain": 'TRC',
        "your-balance": 258663,
        "price-btc": 55
    }
]

export default DropData;