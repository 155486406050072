import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0,0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0,0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0,0.0 b');
}

export function fToken(number) {
  if (number >= 1e8) {
    return Math.floor(number)
  }

  const numeralNumber = numeral(+number);

  if (number >= 1e5) {
    return numeralNumber.format('0,0')
  }
  if (number >= 10000) {
    return numeralNumber.format('0,0.0[0]')
  }

  if (number >= 1000) {
    return numeralNumber.format('0,0.0[00]')
  }

  if (number > 9) {
    return numeralNumber.format('0,0.0[00]')
  }

  if (number > 0.0001) {
    return numeralNumber.format('0.0[0000]')
  }

  return Number(number).toFixed(7)
}