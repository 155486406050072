import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

// ----------------------------------------------------------------------

const CustomTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.semiTransparent,
}));

const TypoSemiTransparent = forwardRef(({ children, ...other }, ref) => (
  <CustomTypo ref={ref} {...other}>
    {children}
  </CustomTypo>
));

TypoSemiTransparent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TypoSemiTransparent;
