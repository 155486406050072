import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import hexToRgba from 'hex-to-rgba';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar as MUIAvatar } from '@mui/material';

// ----------------------------------------------------------------------

const TickAvatar = forwardRef(
  ({ bg = '#83BD67', color = '#ffff', height = 32, width = 32, children, sx, ...other }, ref) => {
    const theme = useTheme();

    return (
      <MUIAvatar
        ref={ref}
        sx={{
          fontWeight: theme.typography.fontWeightMedium,
          color: color,
          backgroundColor: hexToRgba(bg, '0.2'),
          padding: theme.spacing(0.5),
          height,
          width,
          ...sx,
        }}
        {...other}
      >
        {children}
      </MUIAvatar>
    );
  }
);

TickAvatar.propTypes = {
  bg: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  sx: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TickAvatar;
