/* eslint-disable react/prop-types */
// @mui
import React from 'react';
import { styled } from '@mui/material/styles';
import { Switch } from '@mui/material';
import hexToRgba from 'hex-to-rgba';
// hooks
// //
// import Iconify from './Iconify';

// Toggle ModeSwitch
// ----------------------------------------------------------------------
const SwapLimit = styled((props) => <Switch disableRipple {...props} />)(({ theme }) => ({
  width: 183,
  height: 50,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    top: 3,
    left: -1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      left: 0,
      right: -1,
      transform: 'translateX(44px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: hexToRgba('#ffff', 0.08),
        '&:before': {
          content: '"Swap"',
          top: 13,
          left: 24,
        },
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiSwitch-thumb': {
        '&:before': {
          content: '"Limit"',
        },
      },
    },
  },
  '& .MuiSwitch-thumb': {
    background:
      'linear-gradient(103.04deg, rgba(255, 233, 134, 0.88) -25.17%, #BC7E20 77.94%, #A74D01 128.68%), #123331',
    width: 83,
    height: 44,
    borderRadius: '24px',
    position: 'relative',

    '&:before': {
      content: '"Swap"',
      color: '#ffff',
      fontSize: 16,
      fontWeight: 500,
      position: 'absolute',
      top: 10,
      left: 20,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    padding: 3,
    backgroundColor: hexToRgba('#ffff', 0.08),
    borderRadius: '28px',
    position: 'relative',
    '&:before': {
      position: 'absolute',
      content: '"Limit"',
      color: '#ffff',
      fontSize: 16,
      fontWeight: 500,
      top: 13,
      right: 26,
    },
  },
}));

export default function SettingMode({ onChange = () => { }, value }) {
  return (
    <SwapLimit
      inputProps={{ 'aria-label': 'controlled' }}
      value={value}
      checked={value === 'LIMIT'}
      onChange={onChange}
      data-right="LIMIT"
      data-left="SWAP"
    />
  );
}
